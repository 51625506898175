import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  Button,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import NutritionBlog from './NutritionBlog';
import useFetchBlogPosts from '../hooks/useFetchBlogPosts';
import { useState } from 'react';

const Banner = styled(Card)({
  position: 'relative',
  color: '#fff',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
  marginTop: '20px',
});

const BannerContent = styled(CardContent)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '20px',
  borderRadius: '10px',
});

const CategoryCard = styled(Card)({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
  },
  borderRadius: '12px',
  overflow: 'hidden',
});

const Nutrition = () => {
  const { blogPosts, loading, error } = useFetchBlogPosts();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 8;

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(blogPosts.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (selectedCategory) {
    return (
      <NutritionBlog
        title={selectedCategory.title}
        content={selectedCategory.content}
        image={selectedCategory.imageUrl}
      />
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
      <Banner>
        <Box sx={{ position: 'relative', height: '50vh' }}>
          <CardMedia
            component="img"
            image="/images/nutrition/nutrition.png"
            alt="Nutrition Banner"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <BannerContent>
            <Typography variant="h3" component="h1" gutterBottom>
              Welcome to our Nutrition Page
            </Typography>
            <Typography variant="h5">
              Your guide to healthy living
            </Typography>
          </BannerContent>
        </Box>
      </Banner>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {currentPosts.map((post, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <CategoryCard>
              <CardMedia
                component="img"
                height="200"
                image={post.imageUrl}
                alt={post.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="h2" align="center" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  Learn more about {post.title.toLowerCase()}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button 
                  size="small" 
                  variant="contained" 
                  color="primary" 
                  onClick={() => handleCategoryClick(post)}
                >
                  Learn More
                </Button>
              </CardActions>
            </CategoryCard>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button 
          onClick={handlePrevPage} 
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Typography variant="body2" sx={{ mx: 2 }}>
          Page {currentPage} of {Math.ceil(blogPosts.length / postsPerPage)}
        </Typography>
        <Button 
          onClick={handleNextPage} 
          disabled={currentPage === Math.ceil(blogPosts.length / postsPerPage)}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default Nutrition;