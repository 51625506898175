import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import DOMPurify from 'dompurify';

const NutritionBlog = ({ title, content, image }) => {
  // Clean markdown and process colons
  const processedContent = content
    .replace(/#{1,6}\s?/g, '')          // Remove markdown headers (#, ##, ###, etc.)
    .replace(/\*\*(.*?)\*\*/g, '$1')    // Remove bold markers (**text**)
    .replace(/\*(.*?)\*/g, '$1')        // Remove italic markers (*text*)
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$1') // Remove markdown links, keeping text
    .replace(/([.!?])\s+(\S[^:]*?:\s*[^:]*)/g, '$1</p><p>$2') // Break at sentence end before subtitle
    .replace(/(\S[^:]*?):\s*([^:]*)/g, '$1:</p><p>$2'); // Break before and after colon for standalone subtitles

  const sanitizedContent = DOMPurify.sanitize(`<p>${processedContent}</p>`);

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 8,
        mb: 6,
        py: 4
      }}
    >
      {image && (
        <Box
          sx={{
            mb: 6,
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <img
            src={image}
            alt={title}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              display: 'block'
            }}
          />
        </Box>
      )}

      <Typography
        variant="h3"
        component="h1"
        sx={{
          mb: 4,
          lineHeight: 1.2,
          fontWeight: 700
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
        sx={{
          lineHeight: 1.8,
          '& p': {
            mb: 3
          },
          '& ul, & ol': {
            mb: 3,
            pl: 4
          },
          '& li': {
            mb: 1
          }
        }}
      />
    </Container>
  );
};

export default NutritionBlog;