import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import DOMPurify from 'dompurify';
import firebase from './firebase';

const processContent = (content) => {
  const processed = content
    .replace(/#{1,6}\s?/g, '') // Remove headers
    .replace(/\*\*(.*?)\*\*/g, '$1') // Bold
    .replace(/\*(.*?)\*/g, '$1') // Italic
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$1') // Links
    .replace(/([.!?])\s+(\S[^:]*?:\s*[^:]*)/g, '$1</p><p>$2') // Paragraph breaks
    .replace(/(\S[^:]*?):\s*([^:]*)/g, '$1:</p><p>$2');

  return DOMPurify.sanitize(`<p>${processed}</p>`);
};

const BlogDetails = () => {
  const { id } = useParams();
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    const database = firebase.database();
    const postRef = database.ref(`posts/${id}`);

    postRef.on('value', (snapshot) => {
      const postData = snapshot.val();
      setPost(postData);
    });

    return () => postRef.off();
  }, [id]);

  if (!post) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  const sanitizedContent = processContent(post.content);

  return (
    <Container maxWidth="md" sx={{ mt: 10, mb: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        {post.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Posted on {new Date(post.createdAt).toLocaleDateString()}
      </Typography>
      <Box mt={4}>
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          sx={{
            lineHeight: 1.8,
            '& p': { mb: 3 },
            '& ul, & ol': { mb: 3, pl: 4 },
            '& li': { mb: 1 },
          }}
        />
      </Box>
    </Container>
  );
};

export default BlogDetails;
